import React, { Component } from 'react'
import Select, { components } from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import 'react-tooltip/dist/react-tooltip.css'
import { withTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

class TicketSearch extends Component {


  state = {
            data: [],
            load: false,
            passengerNumberSelect: [1,2,3,4,5,6,7,8,9,10],
            errors: {},
            searching:false,
            showTickets: false,
            dialogOpen: false,
            showAlert: false,
            alertMsg: '',
            startingCity:'Duhok'
        };

  constructor(props) {
    super(props);
  }

  async componentDidMount(){
    // const {data} = await axios.get("https://staffportal.ramatravel.net/api/"+this.props.i18n.language+"/cities");

    // data.forEach(function(item) {
    //     item.icon = '/images/location.png';
    // });

    this.setState({ data: this.props.ticketsCities }, ()=>{
        this.setState({load: true});
    });
    
  }

  handleDatePickerChange = (e) => {
    this.setState({datePickerIsOpen: !this.state.datePickerIsOpen});
    // this.setState({datePickerStartDate: e});
    this.props.setDatePickerValue(e);
  };
  
  handleDatePickerClick = (e) => {
    e.preventDefault();
    this.setState({datePickerIsOpen: !this.state.datePickerIsOpen});
  };

  selectTicket = (value) => {
    this.setState({selectTicket: value, showPayment: true, showTickets: false});
  }

  handleClickOpenDialog = () => {
    this.setState({dialogOpen : true});
  };

  handleCloseDialog = (event, reason) => {
    this.setState({datePickerIsOpen: !this.state.datePickerIsOpen});
    if (reason !== 'backdropClick') {
      this.setState({dialogOpen : false});
    }
  };

  ticketSearch = () => {

    if(this.props.destinationValue == null) 
    {
      this.setState({showAlert : true, alertMsg: 'alertNoDestinationPicked' });
      return;
    }

    this.props.ticketSearch();
  }

  handleAlertClose = () => {
    this.setState({showAlert : false, alertMsg: '' });
    this.props.setNoTicketsFound(false);
  }

  handleChangeStartingCity = (event) => {
    const v=event.target.value;
    const lang = this.props.i18n.language;
    if(v=='Shirnex')
    this.props.setDestinationValue(this.state.data[0].value, (lang=='en'? this.state.data[0].enlabel.toLowerCase() : lang=='ar'? this.state.data[0].arlabel.toLowerCase(): lang=='ckb'? this.state.data[0].ckblabel.toLowerCase(): '') );
    this.setState({startingCity : v });

    this.props.setFromValue(this.state.startingCity);
  }

  changeVehicle=(vehicle)=>{
    this.props.setVehicleType(vehicle); 
    // this.setState({startingCity:'Duhok'})
  }

  render() {

    const{t, i18n}=this.props

    const {vehicleType, destinationValue, fromValue, datePickerStartDate, passengerNumber, tickets, selectTicket} = this.props;
    
    const transformedData = this.state.data.map(item => ({
      value: item.value,
      label: i18n.language=='en'? item.enlabel.toLowerCase() : i18n.language=='ar'? item.arlabel.toLowerCase(): i18n.language=='ckb'? item.ckblabel.toLowerCase(): '',
    }));
    const foundItem = transformedData.find(item => item.value === destinationValue);

    let dateformat = this.props.i18n.language == "en"? 'en-US': 'ar-EG';
    Option = (props) => (
      <components.Option {...props}>
        <div dir={i18n.dir()} className="city-option">
          <img src={props.data.icon} alt="logo" className="city-logo" />
          {i18n.language=='en'&& props.data.enlabel}
          {i18n.language=='ar'&& props.data.arlabel}
          {i18n.language=='ckb'&& props.data.ckblabel}
        </div>
      </components.Option>
    );

    // const SingleValue = ({ children, ...props }) => (
    //   <components.SingleValue {...props}>
    //     <img src={props.data.icon} alt="s-logo" className="selected-logo" />
    //     {children}
    //   </components.SingleValue>
    // );
    
    return (
      <section className={`lg:mb-0 ${this.state.showTickets? 'bg-gray-100': ''} relative w-full`}>

        <div className={`ticket-search flex flex-col w-full lg:w-4/6 m-auto p-4 absolute bottom-[20rem] left-[50%] translate-x-[-50%] lg:bottom-[10rem] mb-[-16rem] lg:mb-[-6rem] `}>
          <header className='flex flex-row mx-auto border border-[#550200] w-72 h-16 rounded-md mb-2 bg-white opacity-95'>
              <button className={`rounded-l-md rounded-r-md h-full text-center ${vehicleType == 'car'? 'vehicleBtnActive': ''}`} onClick={() =>{ this.changeVehicle('car')} }>
                <img src="/images/car-icon.png" className='m-auto' />
                {t('car')}
              </button>
              <button className={`rounded-l-md rounded-r-md h-full text-center ${vehicleType == 'bus'? 'vehicleBtnActive': ''}`} onClick={() => {this.changeVehicle('bus');} }>
                <img src="/images/bus-icon.png" className='m-auto' />
                {t('bus')}
              </button>
              <button className={`rounded-r-md rounded-l-md h-full text-center ${vehicleType == 'mini-bus'? 'vehicleBtnActive': ''}`} onClick={() => this.changeVehicle('mini-bus') }>
                <img src="/images/mini-bus-icon.png" className='m-auto' />
                {t('van')}
              </button>
          </header>
          <section className='flex flex-wrap gap-y-1 lg:gap-2 w-full justify-center items-center'>
              
              <div className={`${this.props.showTickets? 'hidden': ''} w-full h-20 lg:w-5/12 flex flex-col border border-[#550200] shadow-sm shadow-[#550200] rounded-md  bg-white opacity-95`}>
                  {/* <input type="text" placeholder='دهوک، عراق - دهوک تیرمینال' className='p-1 border-b border-gray-300' /> */}
                  <select onChange={this.handleChangeStartingCity} className='h-10 border-b bg-transparent border-gray-300 px-2 text-gray-600'>
                      <option value='Duhok'>{t('duhokIraq')}</option>
                     { vehicleType=='mini-bus'&&<option value='Shirnex'>{t('shirnex')}</option>}
                  </select>
                  <div className='relative h-10'>
                    <i className={"fa-solid fa-location-dot absolute top-3 start-4 text-gray-400"}></i>
                    <a data-tooltip-id="my-tooltip"
                              data-tooltip-content="field can't stay empty"
                              data-tooltip-place="bottom">

                      <Select
                            className="basic-single ms-8"
                            classNamePrefix="select"
                            defaultValue={this.state.data[0]}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="color"
                            options={ (this.state.startingCity=='Shirnex' && vehicleType=='mini-bus')?[this.state.data[0]] :this.state.data}
                            placeholder={t('city')}
                            onChange={(choice) => this.props.setDestinationValue(choice.value, i18n.language=='en'? choice.enlabel.toLowerCase() : i18n.language=='ar'? choice.arlabel.toLowerCase(): i18n.language=='ckb'? choice.ckblabel.toLowerCase(): '')}
                            menuPortalTarget={document.body}
                            value={foundItem}
                            menuPosition={'fixed'} 
                            
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              
                              colors: {
                                ...theme.colors,
                                primary25: '#e7dad7',
                                primary: '#550200',
                              },
                            })}
                            components = {{ 
                              Option, 
                              // SingleValue
                             }}
                        />
                    
                    <i className={"fa-solid fa-location-dot absolute top-3 "+i18n.dir()=='ltr'?"right-4":"left-4"+" text-gray-400"}></i>

                    </a>
                  {/* {this.props.showTooltip&&<Tooltip  id="my-tooltip" variant='error' isOpen={true} delayShow={0} delayHide={10}/>} */}
                    {/* <input type="text" placeholder='انتالیا، ترکیا' className='p-1 w-full px-10'/> */}
                  </div>
                  
              </div>

              <div className={`${this.props.showTickets? 'hidden': ''} w-1/4 lg:w-1/12  h-20 bg-white opacity-95 border border-[#550200] shadow-sm shadow-[#550200] rounded-md flex justify-center`}>
                  {/* <input type="date"/> */}
                  <div className='text-gray-600 h-20 flex justify-center flex-col items-center leading-4 relative z-50'>
                    
                    <button className="example-custom-input" onClick={(e) => this.handleDatePickerClick(e)}>
                      <span className='block'>{datePickerStartDate.toLocaleDateString(dateformat, { month: "long" })}</span>
                      <span className='block text-xl font-bold'>{datePickerStartDate.toLocaleDateString(dateformat, { day: 'numeric' })}</span>
                      <span className='block'>{datePickerStartDate.toLocaleDateString(dateformat, { weekday: "long" })}</span>
                    </button>
                    
                    {
                      this.state.datePickerIsOpen && (
                        <Dialog disableEscapeKeyDown open={this.state.datePickerIsOpen} onClose={this.handleCloseDialog}>
                          <DialogContent >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <StaticDatePicker      sx={{
                                                      '.MuiDateCalendar-root': {
                                                        // color: '#550200',
                                                        // borderRadius: '0px',
                                                        // borderWidth: '9px',
                                                        // border: '9px solid',
                                                        // backgroundColor: '#bf954988',
                                                      },
                                                    }}
                                                    disablePast={true}
                                                    label="Date picker" 
                                                    // onChange={(e) => this.handleDatePickerChange(e)}
                                                    onClose={ () => this.setState({datePickerIsOpen: !this.state.datePickerIsOpen}) }
                                                    // minDate={ new Date().getDay() }
                                                    onAccept = { (e) => this.handleDatePickerChange(e) }
                                                    />
                                </LocalizationProvider>
                          </DialogContent>
                        </Dialog>
                      )
                    }
                  </div>
              </div>

              <div className={`${this.props.showTickets? 'hidden': ''} w-3/4 lg:w-3/12 flex flex-col h-full border border-[#550200] shadow-sm shadow-[#550200] rounded-md bg-white opacity-95 `}>
                  <select className='h-10 border-b bg-transparent border-gray-300 px-2 text-gray-600'>
                      <option>{t('tourism')}</option>
                  </select>
                  <select 
                  className='h-10 bg-transparent px-2 text-gray-600'
                  value={passengerNumber}
                  onChange={(e) => this.props.handlePassengerNumberChange(e.target.value)}
                  >
                    {
                      this.state.passengerNumberSelect.map(
                        item => <option key={item} value={item}>{item} {t('tourist')}</option>
                        )
                    }
                  </select>
              </div>

              <button className={`${this.props.showTickets? 'w-72': 'w-full lg:w-2/12 lg:h-20'} h-14 flex lg:flex-col bg-[#550200]  opacity-95 text-white rounded-md justify-center items-center transition-all`}
                    onClick={ () => this.ticketSearch() }>
               {this.props.searching&&<CircularProgress sx={{color:'#bf9549'}}/>}
               {!this.props.searching&&<i className="fa-solid fa-magnifying-glass text-lg mx-4"></i>}
                 { !this.props.searching&&<span> {this.props.showTickets? t('searchAgain') : t('findTrips') } </span>}
              </button>
          </section>
        </div>


      <Dialog
        open={this.state.showAlert || this.props.noTicketsFound}
        onClose={this.handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{direction: (this.props.i18n.language == "en"? 'ltr': 'rtl')}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <div className='w-full'>
                <div className='w-full flex flex-row justify-between mb-4'>
                  <i className="fa-solid fa-triangle-exclamation text-xl"></i>
                  <i className="fa-solid fa-xmark cursor-pointer text-xl" onClick={this.handleAlertClose}></i>
                </div>
                <div className='w-max'>{t(this.props.noTicketsFound? 'noTicketsFound': this.state.alertMsg)}</div>
              </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      </section>
    )
  }
}

export default withTranslation()(TicketSearch)
