import React from "react";

export const FromToImage = (props) => {
  return (
    <div className="block max-w-sm relative">
      <img className="" src="../images/fromto.jpg" />
      <div className="absolute top-[55%] translate-y-[-55%] left-[-10px]">{props.from}</div>
      <div className="absolute top-[45%] translate-y-[-45%] right-2">{props.to}</div>
    </div>
  );
};
