import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import React from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#550200",
    },
    secondary: {
      main: green[500],
    },
  },
});

export default function JitsAboutRama(props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  Aos.init();

  return (
    <div className="w-full h-auto" dir={i18n.dir()}>
      <div className="grid items-center w-full h-auto p-0 pb-1 content-center self-center text-center lg:mb-80 md:mb-64 sm:mb-30 mb-20 md:mt-20">
        {/* <div className=" col-start-1 text-center w-full absolute">
          <center>
            <img
              src="/images/redlogo.png"
              alt="Rama Travel"
              className="w-[100px] h-[50px] mb-10 "
            />
          </center>
        </div> */}
        <img
          src="/images/widebg.jpg"
          alt="bg"
          className=" w-full h-auto absolute -z-10 top-0"
        />
      </div>

      <div className="text-center">
        <div className=" inline-block align-middle text-justify md:w-1/2 px-4  py-4 self-center">
          <div className="  pt-10 text-center text-3xl mb-6 text-[#550200]">
            {t("about") + " " + t("rama")}
          </div>
          <div className="  inline-block text-lg ">{t("aboutp1")}</div>
        </div>
        <img
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          className="h-auto w-auto md:w-1/3 inline-block my-2 md:mx-10"
          src="../images/image1.webp"
        />
      </div>
      <div className="text-center">
        <img
          data-aos="fade-left"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          className="h-auto w-auto md:w-1/3  my-2 md:mx-10 hidden md:inline-block"
          src="../images/image2.webp"
        />
        <div className="align-middle inline-block text-lg text-justify md:w-1/2 px-4  py-4 self-center">
          {t("aboutp2")}
        </div>
        <img
          data-aos="fade-left"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          className="h-auto w-auto md:w-1/3 inline-block my-2 md:mx-10 md:hidden"
          src="../images/image2.webp"
        />
      </div>
      <div className="text-center">
        <div className="align-middle inline-block text-lg text-justify md:w-1/2 px-4  py-4 self-center">
          {t("aboutp3")}
        </div>
        <img
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          className="h-auto w-auto md:w-1/3 hidden md:inline-block my-2 md:mx-10"
          src="../images/image3.webp"
        />
        <img
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          className="h-auto w-auto md:w-1/3 md:hidden inline-block my-2 md:mx-10"
          src="../images/image3.webp"
        />
      </div>
      <hr />
      <div className="text-center content-center py-4 ">
        <div className="inline-block md:w-1/3 w-full md:px-2 px-8 align-top text-start">
          <div className="text-2xl text-[#550200]">{t("contactUs")}</div>
          <div>
            <ul>
              <li
                className="py-1"
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-duration="500"
              >
                <div className="hover:bg-[#550200]  hover:text-[#fff] duration-300 text-[#550200] border border-[#550200] rounded-full p-2 w-11 h-11  justify-center items-center inline-block mx-1">
                  <a href="tel:07509004542">
                    <i className="fa-solid fa-phone text-xl"></i>
                  </a>
                </div>
                07509004542
              </li>
              <li
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <div className="hover:bg-[#550200]  hover:text-[#fff] duration-300 text-[#550200] border border-[#550200] rounded-full p-2 w-11 h-11 justify-center items-center inline-block mx-1">
                  <a href="tel:07503334542">
                    <i className="fa-solid fa-phone text-xl"></i>
                  </a>
                </div>
                07505554542
              </li>
            </ul>
          </div>
        </div>
        <div className="inline-block w-full h-[1px] md:w-[1px] md:h-32 bg-black bg-opacity-10"></div>
        <div className="inline-block md:w-1/3 w-full  md:px-2 align-top text-start px-8">
          <div className="text-2xl text-[#550200]">{t("findUs")}</div>
          <div>
            <ul>
              <li
                className="py-1"
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-duration="500"
                data-aos-delay="500"
              >
                <div className="hover:bg-[#550200] hover:text-[#fff] duration-300 text-[#550200] border border-[#550200] rounded-full px-3 py-2 w-11 h-11  justify-center items-center inline-block mx-1">
                  <a href="https://maps.app.goo.gl/fNXQLJYbkcCWmczM8">
                    <i className="fa-solid fa-location-pin text-xl"></i>
                  </a>
                </div>
                Duhok, Zeri Land
              </li>
              <li
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-duration="500"
                data-aos-delay="700"
              >
                <div className="hover:bg-[#550200]  hover:text-[#fff] duration-300 text-[#550200] border border-[#550200] rounded-full px-3 py-2 w-11 h-11 justify-center items-center inline-block mx-1">
                  <a>
                    <i className="fa-solid fa-location-pin text-xl"></i>
                  </a>
                </div>
                Duhok Internation Terminal
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
